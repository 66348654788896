import { ThemeSwitchButton } from '@/components/theme-switch';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Divider,
    Button,
    Tooltip,
} from '@nextui-org/react';
import { Head } from '@/layouts/head';
import React from 'react';
import forms, { check } from '@/stores/auth';
import { navigate } from '@/config/network';
const { login: loginForm } = forms;
const ResetPassword = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { email } = loginForm.data.value;
    return (
        <>
            <Tooltip content="Reset Password">
                <Button
                    isIconOnly
                    color="primary"
                    className="flex justify-center items-center"
                    variant="light"
                    onClick={onOpen}
                >
                    <i className="fa-solid fa-envelope-circle-check"></i>
                </Button>
            </Tooltip>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Reset Password
                            </ModalHeader>
                            <ModalBody>
                                <p>
                                    Please provide your email in order to reset
                                    the password
                                </p>
                                <Input
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                    onChange={loginForm.update('email')}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="danger"
                                    variant="light"
                                    onPress={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    onPress={() =>
                                        loginForm.submit.reset(onClose)
                                    }
                                >
                                    Reset
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
export default function IndexPage({ data }: any) {
    const { email, password } = loginForm.data.value;
    return (
        <>
            <Head title="Login" />
            <div className="relative flex flex-col h-screen justify-center items-center">
                <Card
                    isFooterBlurred
                    onKeyDown={loginForm.events.enter}
                    radius="lg"
                    className="border-none"
                >
                    <CardHeader className="text-xl w-100 flex justify-center mb-4 mt-2">
                        <b>The Chop Shop</b>
                    </CardHeader>
                    <CardBody className="flex flex-col gap-4 mb-2">
                        <Input
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={loginForm.update('email')}
                        />
                        <Input
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={loginForm.update('password')}
                        />
                    </CardBody>
                    <Divider />
                    <CardFooter className="flex gap-4 h-unit-15 min-h-unit-16 flex-row justify-center items-center">
                        <ResetPassword />
                        <Button
                            variant="solid"
                            color="primary"
                            isDisabled={!loginForm.valid.value}
                            className="flex justify-center items-center w-full"
                            onClick={loginForm.submit.login}
                        >
                            Login
                        </Button>
                        <ThemeSwitchButton />
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}

export async function getServerSideProps(context: any) {
    await check(context, 'auth');
    return {
        props: {},
    };
}
